import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, useStaticQuery, graphql } from "gatsby";
import "/src/scss/index.scss";
import logo from "src/images/logo.svg";
import Button from "src/components/button.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";

const Layout = ({
  bannerText,
  pageName,
  lang,
  pageTitle,
  children,
  location,
}) => {
  const prefix = lang === "en" ? "" : `/${lang}`;

  const [navOpen, setNavOpen] = React.useState(false);
  const [navAboutOpen, setNavAboutOpen] = React.useState(false);
  const [navLearnOpen, setNavLearnOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title_en
          title_he
        }
      }
    }
  `);

  let copy = {
    en: {
      quoteButton: `Get a Quote`,
      nav: {
        home: `Home`,
        about: {
          _: `About Us`,
          company: `Our Company`,
          team: `Our Team`,
        },
        learn: {
          _: `Learn About US Life Insurance`,
          what: `What is Life Insurance?`,
          who: `Who Needs Life Insurance?`,
          why: `Why Buy US Life Insurance?`,
          types: `Types of Life Insurance`,
        },
        process: `The Process`,
        testimonials: `Testimonials`,
        blog: `Blog`,
        contact: `Contact`,
      },
      newsletter: {
        title: "Our Newsletter",
        text: `Sign up to receive our newsletter with the latest information on
          Buying Life Insurance. Keep up to date with the international
          marketplace.`,
        emailInput: "Enter your email address",
        button: "SUBSCRIBE",
      },
      copyright: "Copyright 2021 Spetner Associates, Inc. All Rights Reserved.",
    },
    he: {
      quoteButton: `קבל הצעת מחיר`,
      nav: {
        home: `בית`,
        about: {
          _: `אודות`,
          company: `החברה שלנו`,
          team: `הצוות שלנו`,
        },
        learn: {
          _: `קבל מידע אודות בטוח`,
          what: `מהו ביטוח חיים?`,
          who: `מי צריך ביטוח חיים?`,
          why: `מדוע לקנות ביטוח חיים אמריקאי?`,
          types: `סוגים של ביטוחי חיים`,
        },
        process: `תהליך רכישת ביטוח`,
        testimonials: `המלצות`,
        blog: `בלוג`,
        contact: `צור קשר`,
      },
      newsletter: {
        title: "Our Newsletter",
        text: `Sign up to receive our newsletter with the latest information on
          Buying Life Insurance. Keep up to date with the international
          marketplace.`,
        emailInput: "Enter your email address",
        button: "SUBSCRIBE",
      },
      copyright: (
        <span>
          2021 <span dir="ltr">Spetner Associates, Inc.</span>
          {" - "}
          <span dir="rtl">כל הזכויות שמורות.</span>
        </span>
      ),
    },
  };

  copy = copy[lang];

  const angleDown = <FontAwesomeIcon icon={faAngleDown} />;

  const Banner =
    pageName === "home" ? (
      <div className="bg-home-banner banner bg-cover bg-no-repeat rtl-flip bg-no-repeat">
        <h1 className="text-4xl md:text-6xl font-bold text-blue bg-white bg-opacity-70 object-right-bottom right-0 bottom-0 mb-20 lg:mb-56 p-8 pr-20 md:absolute uppercase">
          {bannerText}
        </h1>
      </div>
    ) : (
      <div className="bg-home-banner bg-no-repeat bg-cover text-center py-20 bg-center">
        <div className="bg-white bg-opacity-70 py-20 ">
          <h1 className="text-4xl md:text-6xl px-4 font-bold text-blue inner-content mx-auto leading-relaxed">
            {pageTitle}
          </h1>
        </div>
      </div>
    );

  const basePath = location.pathname.replace(/^\/([a-z]{2}\/|he$)/, "/");

  return (
    <div
      dir={lang === "he" ? "rtl" : "ltr"}
      className={lang === "he" ? "rtl" : ""}
    >
      {/* <div className="fixed left-0 top-0 w-20 h-20 bg-blue z-50 text-white text-center">
        <div className="sm:block md:hidden">sm</div>
        <div className="hidden md:block lg:hidden">md</div>
        <div className="hidden lg:block xl:hidden">lg</div>
        <div className="hidden xl:block 2xl:hidden">xl</div>
        <div className="hidden 2xl:block">2xl</div>
      </div> */}
      <Helmet>
        <title>{`${pageName === "home" ? "" : pageTitle + " | "}${
          data.site.siteMetadata[`title_${lang}`]
        }`}</title>
      </Helmet>
      <div className="lg:container lg:mx-auto mt-3 z-10 relative">
        <header className="inner-content mx-auto">
          <div className="lg:flex lg:flex-row">
            <div className="logo">
              <Link to={`${prefix}/`} className="">
                <img className="mx-auto" alt="Home" src={logo} />
              </Link>
            </div>
            <div className="lg:flex-grow mt-10 lg:mt-0">
              <div className="w-full md:flex md:flex-row space-x-4 items-center justify-center text-center">
                <div className="lg:flex-grow hidden lg:block"></div>
                <div className="intl">
                  <Link className="flag flag-us" to={basePath}>
                    English
                  </Link>{" "}
                  |{" "}
                  <Link className="flag flag-il" to={`/he${basePath}`}>
                    עברית
                  </Link>
                </div>
                <div className="phone" dir="ltr">
                  <a href="tel:+13144420000">+1-314-442-0000</a>
                </div>
                <div className="mx-auto inline-block mt-2 md:mt-0">
                  <Button href={`${prefix}/get-a-quote`} arrow>
                    {copy.quoteButton}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <nav className="navlinks lg:flex lg:flex-row mt-3 -mb-3 w-full lg:w-auto">
            <div className="flex-grow hidden lg:block"></div>
            <div className="md:hidden bg-blue-dark text-white mt-4 ">
              <button
                className="cursor-pointer px-4 py-2"
                onKeyDown={({ key, preventDefault }) => {
                  if (key === "Enter") {
                    //        preventDefault();
                    setNavOpen(!navOpen);
                  }
                }}
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>
            <ul
              className={`${
                navOpen ? "block" : "hidden"
              } md:block md:flex md:flex-row navbar uppercase text-white font-light bg-blue-dark lg:rounded-lg
                            py-4 px-4 md:px-0 lg:px-4 gap-4
                            space-y-4 md:space-y-0
                            md:text-xs lg:text-sm xl:text-lg md:mt-10 lg:mt-0 lg:mb-0
                            justify-center lg:justify-start`}
            >
              <li>
                <Link to={`${prefix}/`}>{copy.nav.home}</Link>
              </li>
              <li className="group relative">
                <div
                  tabIndex="0"
                  className="cursor-pointer"
                  role="menuitem"
                  onKeyDown={({ key, preventDefault }) => {
                    if (key === "Enter") {
                      setNavAboutOpen(!navAboutOpen);
                    }
                  }}
                  onClick={() => {
                    setNavAboutOpen(!navAboutOpen);
                  }}
                >
                  {copy.nav.about._} {angleDown}
                </div>
                <ul
                  className={`${
                    navAboutOpen || "hidden"
                  } md:absolute bg-blue-dark top-full px-4 pt-2 md:py-4 -mx-2 rounded-lg w-max md:hidden md:group-hover:block space-y-4 md:pt-6`}
                >
                  <li>
                    <Link to={`${prefix}/about-us`}>
                      {copy.nav.about.company}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${prefix}/about-us/our-team`}>
                      {copy.nav.about.team}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="group relative">
                <div
                  tabIndex="0"
                  className="cursor-pointer"
                  role="menuitem"
                  onKeyDown={({ key, preventDefault }) => {
                    if (key === "Enter") {
                      setNavLearnOpen(!navLearnOpen);
                    }
                  }}
                  onClick={() => {
                    setNavLearnOpen(!navLearnOpen);
                  }}
                >
                  {copy.nav.learn._} {angleDown}
                </div>
                <ul
                  className={`${
                    navLearnOpen || "hidden"
                  } md:absolute bg-blue-dark top-full px-4 pt-2 md:py-4 -mx-2 rounded-lg w-max md:hidden md:group-hover:block space-y-4 md:pt-6`}
                >
                  <li>
                    <Link to={`${prefix}/learn/what-is-life-insurance`}>
                      {copy.nav.learn.what}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${prefix}/learn/who-needs-life-insurance`}>
                      {copy.nav.learn.who}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${prefix}/learn/why-buy-us-life-insurance`}>
                      {copy.nav.learn.why}
                    </Link>
                  </li>
                  <li>
                    <Link to={`${prefix}/learn/types-of-life-insurance`}>
                      {copy.nav.learn.types}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={`${prefix}/the-process`}>{copy.nav.process}</Link>
              </li>
              <li>
                <Link to={`${prefix}/testimonials`}>
                  {copy.nav.testimonials}
                </Link>
              </li>
              <li>
                <Link to={`${prefix}/blog`}>{copy.nav.blog}</Link>
              </li>
              <li>
                <Link to={`${prefix}/contact`}>{copy.nav.contact}</Link>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      {Banner}
      <div className="mx-auto">
        <main>{children}</main>
      </div>
      <article className="bg-newsletter-banner bg-bottom bg-cover bg-no-repeat relative z-index-10 pt-20 pb-40 text-center rtl-flip">
        {/* <h2 className="text-white mt-32 mb-8 text-4xl">
          {copy.newsletter.title}
        </h2>
        <p className="text-white leading-7 text-lg font-light w-2/3 mx-auto mb-8">
          {copy.newsletter.text}
        </p>

        <input
          type="email"
          placeholder={copy.newsletter.emailInput}
          className="display-block w-96 focus:outline-none rounded px-2 py-2 bg-no-repeat bg-right"
          style={{
            backgroundImage: `url(${getSVGURI(faTelegramPlane, "#004581")}`,
            backgroundPosition: "98% center",
            paddingRight: "5%",
            backgroundSize: "24px",
            width: "36rem",
          }}
        />
        <div className="mt-8">
          <Button arrow className="mx-auto border-white ">
            {copy.newsletter.button}
          </Button>
        </div> */}
        <div className="h-72 temp"></div>
      </article>
      <article className="inner-content mx-auto mb-20">
        <div className="mx-10">&copy; {copy.copyright}</div>
      </article>
    </div>
  );
};

export default Layout;
